.years {
  width: 240px;
  padding: 0 20px 0 0;
}

.years-container {
  height: 250px;
  overflow-y: auto;
}

.years-container::-webkit-scrollbar {
  display: none;
}

.year {
  background: rgba(182, 178, 178, 0.44);
  text-align: left;
  padding: 5px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgb(182, 178, 178);
}

.year p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.year-months {
  display: none;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}

.year-month {
  padding: 5px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
}

.year-month:hover {
  background: #99bdec;
  border: 1px solid #5d7492;
}

.show-month {
  display: grid;
}

.active-year-month {
  background: #307fc1 !important;
  color: #ffffff !important;
}

.year-month p {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}