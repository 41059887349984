.calendar-container {
  width: 440px;
  margin: auto;
  display: none;
  transition: 0.5s all;
  padding: 14px 16px 18px;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.22);
  border: solid 1px #f4f4f4;
  background-color: #fff;
}

.calendar-main .show {
  display: flex;
}

.calendar-main .calendar-header {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-main .calendar-header button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.calendar-arrowBtn {
  display: flex;
  gap: 5px;
}

.calendar-button img {
  margin-left: 8px;
}

.calendar-button {
  font-size: 500;
  font-size: 12px;
  color: #000;
}

.calendar-main .month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  padding: 0px 10px 10px 10px;
}

.calendar-main .day-heading {
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
  text-align: center;
}

.calendar-main .day-heading p {
  color: rgba(0, 0, 0, 0.38);
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
}

.calendar-main .day {
  color: #808080;
  padding: 4px;

}

.calendar-divder {
  border-right: 1px solid #ccc;
}

.calendar-main .day p {
  font-size: 12px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  font-weight: 400;
  height: 24px;
  width: 24px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-main .current-month {
  color: #000000;
  border-radius: 50%;
}

.calendar-main .day p:hover {
  background: #99bdec;
  border: 1px solid #5d7492;
}



.time-slot {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  height: 275px;
}

.time-slot::-webkit-scrollbar {
  display: none;
}

.hours,
.minutes,
.meridiems {
  width: 45px;
  overflow-y: auto;
  scrollbar-color: transparent transparent;
  border-bottom: none;
}

.hours::-webkit-scrollbar {
  display: none;
}

.hour,
.minute,
.meridiem {
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}



.hour:hover,
.minute:hover,
.meridiem:hover {
  background: #99bdec;
  border: 1px solid #5d7492;
}

.minutes::-webkit-scrollbar {
  display: none;
}

.selected p {
  background: #307fc1 !important;
  color: #ffffff !important;
}

.hour.selected,
.minute.selected {
  background: #307fc1 !important;
  color: #ffffff !important;
}

.meridiem.selected {
  background: #307fc1 !important;
  color: #ffffff !important;
}

.disabled {
  cursor: not-allowed;
  color: gray;
}

.footer-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.footer-button button {
  background: transparent !important;
  border: none;
  outline: none;
  color: #307fc1;
  font-size: 12px;
  font-weight: 500;
}


.selected.disabled {
  background: #ccc !important;
}

