.password-strength-meter {
    text-align: left;
}

.password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 40rem;
    height: 1.6rem;
    margin: 0.4rem 0 0;
    padding: 0 4.6rem 0 0;
    border-radius: 8px;
    border: solid 1px #d8d8dd;
    background-color: #fff;
}

.password-strength-meter-progress::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 8px;
    width: 40rem;
}

.password-strength-meter-label {
    width: 17.7rem;
    height: 1.6rem;
    margin: 0 24.3rem 0.8rem 0;
    font-family: Rubik;
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #5d6870;
    text-transform: uppercase;
}

.password-strength-meter-progress::-webkit-progress-value {
    border-radius: 8px;
}

.strength-Low::-webkit-progress-value {
    background-image: linear-gradient(135deg, #ae0142, #c14830);
}

.strength-Fair::-webkit-progress-value {
    background-color: #FFE066;
}

.strength-Good::-webkit-progress-value {
    background-color: #247BA0;
}

.strength-High::-webkit-progress-value {
    background-image: linear-gradient(92deg, #01ae8f, #30c17c);
}